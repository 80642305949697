.details-box-border {
  border: 1px solid #cdcdcd !important;
  border-radius: 4px !important;
  padding-bottom: 24px !important;
}
.details-sub-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #1d1b1b !important;
  background: #f6f6f6 !important;
  padding: 11px 24px 8px 24px !important;
  border-radius: 4px 4px 0px 0px !important;
}
.job-align-center-with-gap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}
