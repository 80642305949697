/* .form-wrapper {
  padding-top: 20px;
} */
.form-heading-new {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  padding: 0px 50px 20px !important;
}
.resource-form-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  padding: 20px 50px !important;
}
.form-heading-1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.padding-y-20 {
  padding: 0px 20px !important;
}
.padding-y-40 {
  padding: 0px 40px !important;
}
.padding-y-60 {
  padding: 0px 60px !important;
}
.padding-y-50 {
  padding: 0px 50px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .padding-y-50 {
    padding: 0px 16px !important;
  }
  .padding-y-60 {
    padding: 0px 16px !important;
  }
}
.padding-20-60-40 {
  padding: 20px 60px 40px !important;
}
.padding-20 {
  padding: 20px !important;
}
.form-perent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.form-child {
  height: calc(100vh - 308px);
  overflow: auto;
}
/* .resource-form-perent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
} */
.resource-form-child {
  height: calc(100vh - 276px);
  /* height: calc(100vh - 225px); */
  overflow: auto;
}
.button-child {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* box-shadow: ; */
  /* paddin: 3%; */
}
.filedArray-perent {
  border: 1px solid #dedede;
  padding: 20px;
  margin: 0px 40px !important;
  border-radius: 4px;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.padding-x-20 {
  padding: 20px 0px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gap-20 {
  display: flex;
  flex-direction: column;
  gap: 20px !important;
}
.add-details-span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #0160b9;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.resource-table-btn-main {
  display: flex;
  align-items: center;
  gap: 20px;
}
.add-new-schedule {
  padding: 5px 50px 30px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .resource-table-btn-main {
    gap: 10px;
  }
  .resource-form-heading {
    padding: 20px 16px !important;
  }
  .add-new-schedule {
    padding: 5px 16px 30px;
  }
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-center-with-gap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
}
.font-size-14 {
  font-size: 14px;
  line-height: 20px;
}
.font-size-14-with-semibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.font-size-14-with-semibold-primary {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #0160b9;
}

/* //! task-type-input */

.task-type-input {
  width: 100%;
  padding: 8px;
  background-color: #f6f6f6;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  font-size: 14px!important;
}
.type_list {
  display: flex;
  border-bottom: 1px solid #dedede;
  padding: 12px 10px;
  align-items: center
  /* margin-top: 10px; */
}
.arrow {
  cursor: pointer;
}
.parent-search-box {
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
  padding: 7px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px !important;
}
.parent-search-box1 {
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
  padding: 7px 10px;
  border-radius: 4px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* margin-top: 10px; */
  font-size: 14px !important;
}
.selected-search-box {
  color: #000000;
  border: 1px solid #8a8a8a;
  padding: 7px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px!important;
}
.selected-search-box1 {
  color: #000000;
  border: 1px solid #8a8a8a;
  padding: 7px 10px;
  border-radius: 4px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* margin-top: 10px; */
  font-size: 14px!important;
}
.schedule-selected-search-box {
  color: #000000;
  border: 1px solid #8a8a8a;
  padding: 7px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px!important;
  /* gap: 15px; */
}
.type-menu-scroll{
  height: 200px;
  overflow-y: auto;
}
.parent-check{
  position: absolute;
  z-index: 999;
  background-color: white;
  width: 97%;
  box-shadow: 0px 0px 3px #727272;
  padding: 10px;
}
.progress-parent{
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-parent-machine-main{
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}