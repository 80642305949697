.job-slips-main-border {
  border: 1px solid #cdcdcd;
  border-radius: 3px;
}
.job-slips-basic {
  border-bottom: 1px solid #cdcdcd;
  padding: 8px 24px;
  border-radius: 3px 3px 0px 0px;
  background: #f6f6f6;
  font-size: 16px;
  font-weight: 600;
  color: #1c1c1c;
}
.job-slips-grid-section {
  padding: 24px 24px 40px 24px;
}
.job-slips-card-box {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #1c1c1c;
  display: flex;
  justify-content: space-between;
}
.job-slips-card-box-1 {
  font-size: 16px;
  font-weight: 600;
  color: #1c1c1c;
  display: flex;
  justify-content: space-between;
}
.job-slips-main-margin {
  margin: 20px 0px;
}
.job-slips-task-assigned {
  display: flex;
  gap: 10px;
}
.job-slips-add-task-header {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.wrapper-task {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-slips-with-semibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1c1c1c;
}
.job-slips-shift-report {
  width: 350px;
  height: 188px;
  border: 1px solid #dedede;
  background: #f0f6ff;
  margin-top: 40px;
  padding: 50px;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.job-slips-main-box {
  display: flex;
  gap: 40px;
}
.job-slips-main-box-ongoing {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 40px;

}
.parent-ongoing-box{
  height: calc(100vh - 160px);
  display: grid;
  place-items: center;
}
.job-slips-main-box-planned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 77vh;
}
.job-slips-shift-report-date {
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 44px !important;
  color: #020202 !important;
}
.job-slips-shift-report-days-name {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 44px !important;
  color: #020202 !important;
}
.job-slips-shift-day {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 44px !important;
  color: #265aa8 !important;
}
.job-slips-overflow-auto {
  height: calc(100vh - 155px);
  overflow: auto;
  padding: 10px 50px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .job-slips-overflow-auto {
    padding: 10px 16px !important;
  }
  .job-slips-shift-report {
    padding: 50px 20px;
  }
}
.parent-jobnumber {
  display: flex;
  justify-content: center;
  height: 400px;
  align-items: center;
}
.print-jobslips-number{
  font-size: 20px!important;
font-weight: 600;
line-height: 32px;
padding: 20px 0px 30px 0px;
}
.print-job-slips-overflow-auto {
  /* height: calc(100vh - 155px); */
  /* overflow: auto; */
  padding: 10px 50px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .print-job-slips-overflow-auto {
    padding: 10px 16px !important;
  }
  .print-job-slips-shift-report {
    padding: 50px 20px;
  }
}
.print-overflow-hidden {
  height: 100vh;
  overflow: hidden;
}