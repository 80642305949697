.system-health-heading{
    font-size: 16px;
    font-weight: 600;
    border-right: 1px solid #C0C0C0;
    padding: 15px 15px 15px 30px;
    display: flex;
    align-items: center;
    text-align: center;
}
.system-health-item-data{
    border-right: 1px solid #C0C0C0;
    padding: 15px 15px 15px 30px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-align: center;
}
.system-health-overflow-auto {
    height: calc(100vh - 85px);
    overflow: auto;
    padding: 30px 50px !important;
  }
  .health-default-input {
    border-radius: 8px !important;
    border-color: #8a8a8a !important;
    font-size: 14px !important;
    padding: 0px 14px;
    line-height: 0px;
    height: 38px !important;
    width: 240px;
    color: #8a8a8a !important;
  }
  .health-default-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 38px !important;
    font-size: 14px !important;
    padding: 5px 14px;
    line-height: 0px;
    border-color: #8a8a8a !important;
    color: #8a8a8a !important;
    border-radius: 8px !important;

  }

  .health-modal-main-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .health-modal-box{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    width: 300px;
  }