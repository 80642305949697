.create-report-box {
  border: 1px solid #c0c0c0;
  padding: 40px;
}
.create-report-kpi {
  border: 1px solid #8a8a8a;
  color: #8a8a8a;
  padding: 10px 12px;
  border-radius: 4px;
}
.create-report-kpi-selected {
  border: 1px solid #8a8a8a;
  color: #8a8a8a;
  background: #dceaff;
  padding: 10px 12px;
  border-radius: 4px;
}
.create-reoport-field {
  gap: 15px;
  /* padding-bottom: 50px; */
  width: 344px !important;
}
.btn-field-end {
  display: flex;
  align-items: flex-end;
  /* padding-left: 15px; */
  padding-top: 20px;
}
.report-form-wrapper {
  padding-top: 20px;
}
.report-form-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  padding: 20px 40px !important;
}
.report-form-heading-1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padding-y-40 {
  padding: 0px 40px !important;
}
.padding-y-60 {
  padding: 0px 60px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .padding-y-60 {
    padding: 0px 16px !important;
  }
}
.report-form-perent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.report-form-child {
  height: calc(100vh - 245px);
  overflow: auto;
}
.report-button-child {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  gap: 15px;
}
.common-tabel-heading1 {
  padding-bottom: 30px;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.common-tabel-heading {
  padding-bottom: 20px;
  padding-top: 20px;
}
.report-yes-no {
  display: flex;
  gap: 15px;
}

.report-table-btn-main {
  display: flex;
  align-items: center;
  gap: 20px;
}
.report-delete {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.active {
  background: #0160b9 !important;
  color: #ffffff !important;
}
.create-main-box {
  margin-bottom: 10px;
  display: flex;
}
.create-add-btn {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 27px;
}
.create-email-section {
  padding-bottom: 50px;
}
.select-report-kpi {
  width: 254px;
  height: 135px;
  border: 1px solid #c0c0c0;
}
.create-report-date {
  width: 344px !important;
  height: 40px !important;
}
