@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

body * {
  box-sizing: border-box;
  background-color: none !important;
}

.test {
  padding: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc !important;
  transition: border-color 0.3s;
  background-color: #ffffff !important;
  border-radius: 4px;
}
.test {
  padding: 1px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc !important;
  transition: border-color 0.3s;
  width: 232px;
  height: 120px;
  background-color: #ffffff !important;
  border-radius: 4px;
}

.test.border-left {
  padding: 0;
  /* Set padding to zero */
}

.title-round-border-completed {
  border: 1px solid;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  padding: 1px;
  background-color: #1AD04C;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
}
.title-round-border-ongoing {
  border: 1px solid;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  padding: 1px;
  background-color: #FF9900;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
}
.title-round-border-not-started {
  border: 1px solid;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  padding: 1px;
  background-color: #C0C0C0;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
}
.title-round-borders {
  border: 1px solid;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  padding: 1px;
  background-color: #0160B9;
  color: transparent;
  position: absolute;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  transform: translate(-50%, -50%);
  margin-top: 60px;
}
.border-left {
  background-color: rgba(255, 255, 255, 1);
  margin-left: 2px;
  border-radius: 100px;
  padding: 115px 0px 0px 0px;
  border-left: 4px solid #F19E38;
  color: transparent;
    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}
.border-left1 {
  background-color: rgba(255, 255, 255, 1);
  margin-left: 2px;
  border-radius: 100px;
  padding: 115px 0px 0px 0px;
  border-left: 4px solid #1AD04C;
  color: transparent;
    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}
.border-left2 {
  background-color: rgba(255, 255, 255, 1);
  margin-left: 2px;
  border-radius: 100px;
  padding: 115px 0px 0px 0px;
  border-left: 4px solid #C0C0C0;
  color: transparent;
    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}
.titlename {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 20px;
}

.title-ans {
  color: #020202;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
  line-height: 20px;
}

.herdername {
  color: #0160B9;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 0px 3px 2px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.border {
  border-bottom: 1px solid #dedede;
  padding: 2px 0px 3px 3px;
  width: 190px;
  border-radius: 41px;
  margin-left: 20px
}

.item {
  display: flex;
  
}

.item__parent {
  position: relative;
  min-width: 150px;
}

.item_parent_line {
  position: absolute;
  width: 2px;
  background-color: #0160b9;
  right: calc(100% + 75px);
  z-index: 10;
  transition: background-color 0.3s;
  bottom: 0;
}

.item_parent_element {
  min-width: 100px;
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.item_parent_element.has-children::after {
  content: "";
  display: block;
  width: 75px;
  height: 2px;
  background-color: #0160B9;
  position: absolute;
  z-index: 20;
  transition: background-color 0.3s;
  left: 100%;
}

.item_parent_element.has-parent::before {
  content: "";
  display: block;
  width: 75px;
  height: 2px;
  background-color: #0160B9;
  position: absolute;
  z-index: 20;
  transition: background-color 0.3s;
  right: 100%;
}

.round.has-parent::after::before {
  content: "";
  display: inline-block;
  border: 1px solid;
  border-radius: 50%;
  height: 12px !important;
  width: 12px !important;
  padding: 1px !important;
  background-color: #1AD04C !important;
  color: transparent;
  font-size: 0;
  overflow: hidden;
  position: absolute;
  top: 50%; 
  transform: translateY(-50%); 
}

.item_parent.active .itemparent_line.active {
  background-color: #b94e01;
}

.item_parent.active .itemparent_element {
  border-color: #e95313;
}

.item_parent.active .itemparent_element.has-parent::before,
.item_parent.active .itemparent_element.has-children::after {
  background-color: #0160b9;
}
@media (max-width: 1024px) {
  .item__children {
    margin-left: 50px;
  position: relative;
  flex: 1;
  }
}
.item__children {
  margin-left: 150px;
  position: relative;
  flex: 1;
}
.back {
  background-color: red;
}
.parent-react-flow{
 position: absolute;
 width: 100%;
 top: 70px;
}

.card-heding {
  color: #020202;
  font-weight: 600 !important;
  font-size: 18px !important;
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
}


.hr-teams {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  background-color: #f7f9fb;
  overflow: visible;
  height:  auto !important;
  position: relative;
}

@media screen and (min-width: 2560px) and (max-width: 2560px) {
  .hr-teams {
    background-color: transparent;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1440px) {
  .hr-teams {
    background-color: transparent;
  }
}

@media screen and (min-width: 1870px) and (max-width: 2000px) {
  .hr-teams {
    background-color: transparent;
  }
}

.timeline {
  display: flex;
  justify-content: space-between;
}

.status-header {
  border: 2px solid #e3e3e3;
  border-radius: 6px;
  padding: 6px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
  padding: 10px;
  border-radius: 3px;
  width: 200px;
  font-size: 12px;
  color: #0160B9;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #DEDEDE;
  background-color: white;
  height: 100px;
  margin-bottom: 20px;
}
.react-flow__minimap svg {
  display: none;
}
.react-flow__attribution a {
  text-decoration: none;
  color: #999;
  display: none;
}
.react-flow__handle {
  position: absolute;
  pointer-events: none;
  min-width: 0px !important;
  min-height: 0px !important;
  width: 0px !important;
  height: 0px !important;
  background: #1a192b;
  border-radius: 0% !important;
}
.react-flow__renderer {
  cursor: default !important;
  pointer-events: none !important;
}
.reactflow-container {
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.timeline {
  display: flex;
  justify-content: space-between;
}

.status-header {
  border: 2px solid #e3e3e3;
  border-radius: 6px;
  padding: 6px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.card-heading {
  color: #020202;
  font-weight: 600;
  font-size: 18px;
}

.reactflow-container {
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.status-header {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 2px solid #e3e3e3;
  border-radius: 6px;
  padding: 6px;
  font-weight: 500;
  font-size: 14px;
}
.custom-node {
  cursor: pointer;
}
@media (max-width: 1440px) {
  .parent-react-flow1 {
   margin-top: 20px;
  }
}
@media (max-width: 1870px) {
  .parent-react-flow1 {
   margin-left: 55px;
   margin-top: 20px;
  }
}
@media (max-width: 1440px) {
  .jobcard-main-heading-kanban {
   margin-bottom: 20px;
   margin-top: 20px;
  }
}
@media (max-width: 1870px) {
  .jobcard-main-heading-kanban {
    margin-bottom: 20px;
   margin-top: 20px;
   margin-right: 80px;
   gap: 20px;
  }
}



