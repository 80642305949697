.title-head-box {
  display: flex !important;
  justify-content: space-between !important;
  padding: 16px 24px 0px 24px !important;
}
.title-head-box-new {
  display: flex !important;
  justify-content: space-between !important;
  /* padding: 16px 0px 0px 24px !important; */
}
.close-icon {
  cursor: pointer !important;
  font-size: 30px !important;
}

.sub-box {
  display: flex !important;
  justify-content: flex-end !important;
  margin: 24px !important;
}

.modal-sub-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  color: #1d1b1b !important;
  background: #f6f6f6 !important;
  padding: 8px 24px 8px 24px !important;
  margin-top: 24px !important;
}
.modal-body {
  padding-bottom: 20px !important;
}
/* .section-one {
    padding-bottom: 30px !important;
  } */
.margin-bottom {
  margin-bottom: 24px !important;
}
.padding-bottom {
  padding-bottom: 24px !important;
}
.margin-top {
  margin-top: 24px !important;
}
.margin-bottom {
  margin-bottom: 24px !important;
}
.pl-sub-text {
  padding: 0px 0px 0px 24px !important;
}
.pl-sub-text_new {
  padding: 0px 0px 0px 0px !important;
}
.top-up-name {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  color: #1d1b1b !important;
}
