.btn1 {
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  /* text-transform: capitalize; */
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* color: #1976d2; */
  /* border: 1px solid rgba(25, 118, 210, 0.5); */
}
.only-name-btn {
  background-color: transparent !important;
  color: #1976d2 !important;
  text-transform: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.disabled-only-name-btn {
  background-color: transparent !important;
  color: #a9a9a9 !important;
  text-transform: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.report-button-box {
  width: 168px !important;
  border-radius: 4px !important;
  padding: 10px 12px !important;
}
.report-add-btn {
  width: 87px !important;
  height: 40px !important;
  padding: 10px 16px !important;
  border-radius: 4px !important;
}
