.css-1phpx1i-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--light-gray-color) !important;
}

.default-time-picker {
  width: 100% !important;
  border-radius: 4px;
  border-color: #8a8a8a;
  font-size: 14px;
  /* padding: 0px 14px; */
  line-height: 0px;
  height: 40px;
}

.default-time-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 40px !important;
  padding: 0px 14px !important;
  font-size: 14px !important;
}
.css-4jnixx-MuiStack-root {
  /* padding-top: 5px !important; */
  overflow: hidden !important;
}
.css-4jnixx-MuiStack-root > .MuiTextField-root {
  min-width: 100% !important;
  /* padding-top: 0px !important; */
}

.time-title {
  font-size: 14px !important;
  font-weight: 400;
  /* padding: 0px 0px 8px 0px; */
  color: #000;
}
.validation-time {
  color: red;
  font-size: 12px !important;
}
