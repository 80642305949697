.default-select {
  width: 100% !important;
  border-radius: 4px;
  border-color: #8A8A8A !important;
  font-size: 14px !important;
  padding: 0px 14px 0px 0px;
  line-height: 0px;
  height: 40px;
  color: #8A8A8A !important;

}
.validation-text-select {
  font-size: 12px !important;
  color: red;
}
.select-title {
  font-size: 14px !important;
  font-weight: 500;
  padding: 0px 0px 8px 0px;
  color: #000;
}

.disabled-text {
  width: 100% !important;
  border-radius: 4px;
  border-color: #8A8A8A !important;
  font-size: 14px !important;
  padding: 0px 14px 0px 0px;
  line-height: 0px;
  height: 40px;
  color: #2F2F2F !important;
}
.default-select-new {
  width: 100% !important;
  border-radius: 4px;
  border-color: #8a8a8a !important;
  font-size: 14px !important;
  padding: 0px 14px 0px 0px;
  line-height: 0px;
  height: 40px;
  color: #000 !important;

}
.custom-select-machine{
  width: 100% !important;
  border-radius: 4px;
  border-color: #8a8a8a !important;
  font-size: 14px !important;
  padding: 0px 14px 0px 0px;
  line-height: 0px;
  height: 40px;
  color: #000 !important;
}
