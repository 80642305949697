.save-btn {
  display: flex;
  justify-content: flex-end;
}
.process-main-box {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 10px;
}
/* .delete-icon-grid {
  display: flex;
  align-items: center;
  margin-top: 40px !important;
} */
.delete-icon-grid {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.process-delete {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* padding-right: 20px; */
}
.process-delete_disable {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: not-allowed;
  /* padding-right: 20px; */
}
.add-process {
  margin-top: 20px;
}
.process-index {
  font-size: 14px;
  font-weight: 700;
}
