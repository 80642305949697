.job-slips-main-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.job-slips-margin-top {
  margin-top: 25px !important;
}
.approved-yes-btn1 {
  display: flex;
  justify-content: center !important;
  margin-top: 35px !important;
}
.job-slips-main-box {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.job-slips-main-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 50px;
}
.job-slips-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.job-card-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.add-task-header-share-save {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
.add-task-header1 {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
  display: flex;
  gap: 10px;
}
.add-task-header-share {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 600;
  display: flex;
  gap: 10px;
  color: #265aa8;
}
.job-slips-add-task-header {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}

.job-slips-with-semibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1c1c1c;
}
