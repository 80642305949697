.default-input {
  border-radius: 4px;
  border-color: #8a8a8a !important;
  font-size: 14px !important;
  padding: 0px 14px;
  line-height: 0px;
  height: 40px;
  color: #8a8a8a !important;
}
.default-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 40px;
  font-size: 14px !important;
  padding: 0px 14px;
}
.default-input .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 14px;
  font-size: 14px !important;
  color: red;

}
.default-input .MuiOutlinedInput-root .fieldset {
  border-color: #8a8a8a !important;
  border-radius: 4px;
  font-size: 14px !important;

}
.field-title {
  font-size: 14px !important;
  font-weight: 500;
  padding: 0px 0px 6px 0px;
  color: #000;
}
.validation-text {
  color: red;
  font-size: 12px;
}

