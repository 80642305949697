.parent-title {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid #CDCDCD;
    width: 30%;
    /* margin-bottom: 5px; */
}
.font-size-14{
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color:#0160B9 !important;
}