
  @page {
    size: A4;
    margin-top: 7mm;
    margin-bottom: 3mm;
  }
  
  /* Specific styling for the first page */
  @page:first {
    margin-top: initial; /* Remove the margin-top for the first page */
  }
  
  /* Additional CSS to ensure other elements have proper margins */
  @media print {
    body {
      counter-reset: page;
    }
    .page {
      page-break-before: always;
    }
    .jobcard-print-title{
      display: block;
      font-size: 24px;
      font-weight: 600;
      padding: 20px 0px;
    }
    .print-margin-bottom{
      margin-bottom: 30px;
    }
    .jobcard-details-page-border{
      border: 1px solid #CDCDCD;
    }
    .production-team-head-overflow-hidden{
      overflow: auto;
    }
    .common-parent-class-overflow-auto-padding-10-50{
      height: auto;
      padding: 10px 30px;
    }
    .page:first {
      page-break-before: auto;
    }
  }