.form-wrapper {
  padding-top: 20px;
}

.job-form-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  padding: 0px 50px 20px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .job-form-heading {
    padding: 0px 16px 20px !important;
  }
}
.form-heading-1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padding-y-20 {
  padding: 0px 20px !important;
}

.padding-y-40 {
  padding: 0px 40px !important;
}

.padding-y-60 {
  padding: 0px 60px !important;
}
.padding-y-50 {
  padding: 0px 50px !important;
}
.padding-20-50-40 {
  padding: 20px 50px 40px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .padding-y-50 {
    padding: 0px 16px !important;
  }
  .padding-20-50-40 {
    padding: 20px 16px 40px !important;
  }
}

.padding-20-60-40 {
  padding: 20px 60px 40px !important;
}

.padding-20 {
  padding: 20px !important;
}

.form-perent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.form-child-jobcard {
  height: calc(100vh - 276px);
  overflow: auto;
}

.button-child {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.job-filedArray-perent {
  border: 1px solid #dedede;
  padding: 20px;
  margin: 0px 50px !important;
  border-radius: 4px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .job-filedArray-perent {
    margin: 0px 17px !important;
  }
}

.pb-20-task {
  padding-bottom: 10px !important;
}

.padding-x-20 {
  padding: 20px 0px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gap-20 {
  display: flex;
  flex-direction: column;
  gap: 20px !important;
}

.add-details-span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #0160b9;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.table-btn-main {
  display: flex;
  align-items: center;
  gap: 20px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center-with-gap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
}

.font-size-14 {
  font-size: 14px;
  line-height: 20px;
  color: #8a8a8a !important;
}

.font-size-14-with-semibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1c1c1c;
}

.font-size-16-with-semibold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1c1c1c;
  padding: 8px 24px 8px 24px;
}

.font-size-14-with-semibold-primary {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #0160b9;
}

.parent-com {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #cdcdcd;
  width: 40%;
  margin-bottom: 5px;
}

.border-border-radius {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}

.padding-2-border-bg {
  padding: "2px";
  border-bottom: 1px solid #cdcdcd;
  background-color: #f6f6f6;
}

.padding-5 {
  padding: 5px 40px;
}

.padding-x-60 {
  padding: 20px 60px 10px;
}

.padding-10 {
  padding: 10px 40px;
}

.padding-x-40 {
  padding: 20px 40px;
}

.padding-10-2 {
  padding: 10px 2px;
}
.padding-20-75 {
  padding: 20px 75px;
}
.form-child-border {
  overflow: auto;
  border: 2px solid #dedede;
  border-radius: 4px;
}

.form-perent-add-task {
  height: calc(100vh - 279px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding: 0px 50px;
}
.padding-20-75-border {
  padding: 20px 75px;
  border: 2px solid #dedede;
  border-radius: 4px;
}
.padding-20-75-border1 {
  padding: 20px 40px;
  border: 2px solid #dedede;
  border-radius: 4px;
}
.padding-20-40-border{
  padding: 20px 40px;
  border: 2px solid #dedede;
  border-radius: 4px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .form-perent-add-task {
    padding: 0px 16px;
  }
  .padding-20-75-border {
    padding: 20px 50px;
  } 
   .padding-20-75-border1 {
    padding: 20px 40px;
  }
  .padding-20-40-border{
    padding: 20px 40px;
  }
}
.form-perent-add-to-task {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding: 0px 75px;
}
.padding-20-75-border-bottom {
  padding: 20px;
}

.add-task-header {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.padding-20-75 {
  padding: 20px 75px;
}
.border-status {
  border: 1px solid #dedede;
  padding: 0px 0px 10px 10px;
}
.jobdetails-padding {
  padding: 10px 10px 0px 10px;
}
.border-details-card {
  border: 1px solid #dedede;
  margin-top: 20px;
  margin: 10px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
}
.margin-1 {
  margin: 10px 10px 10px 10px;
}
.mainborder {
  border: 1px solid #dedede;
  padding: 10px 10px 20px 10px;
}
.padding-top-bottom {
  padding-bottom: 20px;
}
.add-task-type-header {
  font-size: 14px !important;
  color: #8a8a8a;
  font-weight: 400;
  display: flex;
  line-height: 20px !important;
}
.task-type-form-heading {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #020202;
  line-height: 20px !important;
  display: flex;
}
.add-job-card-overflow-hidden {
  height: 100vh;
  overflow: hidden;
}
.add-part-field-job-card{
padding: 0px 50px;
}

.add-part-job-card-main{
padding: 20px;
border: 1px solid #DEDEDE;
border-radius: 4px;
margin-bottom: 30px;
}
.add-part-jobcard-flex{
  display: flex;
  justify-content: space-between;
}
.add-jobcard-margin-bottom{
  margin-bottom: 20px;
}
.add-jobcard-field{
display: flex;
width: 100%;
gap: 16px;
margin-bottom: 20px;
}
.add-jobcarfield-width{
  width: 33%;
}
.add-jobcard-width-50{
  width: 50%;
}
@media (min-width: 1025px) and (max-width: 1700px) {
  .add-jobcard-width-50{
    width: 70%;
  }
}
.add-part-jobcard-add-details{
color: #0160b9;
border: 1px solid #0160b9;
width: 137px;
height: 40px;
padding: 10px 16px;
border-radius: 4px;
font-size: 14px;
display: flex;
align-items: center;
justify-content: space-between;
font-weight: 500;
cursor: pointer;
}
.addpart-table-border-print-th{
  background: #F6F6F6!important;
  color: #020202;
  font-size: 14px!important;
  font-weight: 400;
  padding: 10px 16px!important;
}
.addpart-table-border-print-td{
  color: #020202;
  font-size: 14px!important;
  font-weight: 400;
  padding: 10px 16px!important;
  border-top: 1px solid #DEDEDE;
  

}
.addpart-table-border-print{
  border: 1px solid #DEDEDE;
  border-spacing: 0;
  font-size: 14px!important;
  border-radius: 8px 8px 0px 0px !important;
 
}
.add-part-default-input {
  border-radius: 4px;
  border: 1px solid #D4D5D6 !important;
  font-size: 14px !important;
  padding: 0px 14px;
  line-height: 0px;
  height: 40px;
  color: black !important;
  width: 100%;
  &:hover{
    border-color: #000000 !important;
  }
  &::placeholder{
    color: #D4D5D6!important;
  }
}
.jobcard-print-title{
  display: none;
}
.print-margin-bottom{
  margin-bottom: 100px;
}
.jobcard-details-page-border{
  border: 1px solid #CDCDCD;
}