.parent-delay {
  display: flex;
  justify-content: space-between;
}

.main-parent-delay {
  padding: 0.6rem 0.8rem;
  border: 1px solid #dedede;
  background: #f6f6f6;
}

.delay-title {
  color: #2d72db;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.time-set {
  color: #265aa8;
  font-weight: 400;
  font-size: 12px;
}

.title-set {
  color: #1d1b1b;
  font-weight: 400;
  font-size: 12px !important;
  padding-top: 8px;
}

/* //! wrapper css */
.parent-wrapper-delay {
  border: 1px solid #c0c0c0;
  padding: 15px;
}

.parent-bar-chart {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #f0f6ff;
  border: 1px solid #dedede;
  width: 49%;
}

.Overall {
  font-size: 16px;
  font-weight: 600;
}

.percentile {
  font-size: 18px;
  font-weight: 700;
}

.header-wrapper {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.view-btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #0160b9;
}

.parent-one-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.delay-job-card-padding {
  padding-bottom: 50px !important;
}
