@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@900&display=swap");

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  /* overflow: auto; */
}
* {
  margin: 0px;
  padding: 0px;
  font-family: Open Sans !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.height-100 {
  height: 100% !important;
}

/* Variables */
:root {
  --theme-background: #f6f6f6;
}

/* Sidebar CSS */
.pro-sidebar > .pro-sidebar-inner {
  background-color: var(--theme-background) !important;
}
.pro-sidebar {
  min-width: 100% !important;
}

.new-class-active {
  color: #fff !important;
}
.new-class {
  color: #020202 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  white-space: break-spaces !important;
  overflow: inherit !important;
}

.sa {
  z-index: 1302 !important;
}

.pro-item-content {
  /* color: white !important; */
  font-size: 14px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  background: #0160b9;
  margin: 0px 12px;
  border-radius: 4px;
}
.pro-sidebar .pro-menu .pro-menu-item .active .pro-inner-item {
  padding: 8px 10px 8px 10px !important;
}
.submenu-head {
  color: #020202 !important;
}

.submenu-head-active {
  background: #0160b9;
  margin: 0px 12px;
  border-radius: 4px;

  color: #020202 !important;
}

.submenu-item {
  margin: 10px 0px;
  color: black !important;
}
.submenu-item-active {
  color: #0160b9 !important;
  font-weight: 600;
  font-size: 14px !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  border-right: 1px solid #dedede !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid #dedede !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background: #f6f6f6 !important;
}
/* VD changes */
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 5px !important;
  line-height: 4px !important;
}

.white-color {
  color: #fff !important;
  font-size: 14px !important;
}
.blue-color {
  color: #0160b9 !important;
  font-size: 14px !important;
}
.black-color {
  color: #020202 !important;
  font-size: 14px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 10px 8px 20px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: inherit !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 5px !important;
}
/* ******* */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 48px;
}
.table-row-font {
  font-size: 0.875rem !important;
  width: 100%;
  font-weight: 600!important;
}

.yellow-bg {
  background-color: #f19e38 !important;
  color: white !important;
}
.blue-bg {
  background-color: #0160b9 !important;
  color: white !important;
}
.tb-margin-left {
  margin-left: 10px;
}
.blue-color {
  color: #0160b9 !important;
  border: 1px solid #0160b9 !important;
}

.white-bg {
  background-color: white !important;
}

.datepicker-btn-wrapper {
  display: flex !important;
  justify-content: space-around !important;
  padding-bottom: 17px !important;
}

.MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 10px !important;
}

.datepicker-btn {
  padding: 10px 16px !important;
  border-radius: 4px !important;
  height: 40px !important;
  text-transform: capitalize !important;
}

.fieldset-p-10 input {
  padding: 10px 223px 10px 10px !important;
}

.datepicker-btn-wrapper-factory-layout {
  display: flex !important;
  justify-content: flex-end !important;
  padding: 10px 0px !important;
  border-top: 1px solid #dedede !important;
  margin: 0px 15px !important;
}

.datepicker-btn-factory-layout {
  padding: 10px 16px !important;
  border-radius: 4px !important;
  margin: 0px 10px !important;
  height: 40px !important;
  text-transform: capitalize !important;
}
/* BreadCrumb style */
.breadcrumb-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #265aa8;
  cursor: pointer;
  /* text-transform: capitalize; */
}

.fw-bold {
  color: white !important;
}
.MuiBreadcrumbs-separator {
  color: #265aa8;
  margin: 0px 5px !important;
}
.divider-color,
.MuiDivider-root {
  border-color: #dedede !important;
  margin: 30px 0px !important;
}

.title-typography {
  line-height: 32px !important;
  height: 32px !important;
  font-size: 18px !important;
  margin: 0px 0px 18px 0px !important;
  font-weight: 600 !important;
}
.field-title {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0px 0px 8px 0px !important;
  color: #000;
}
.field-title1 {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0px 0px 8px 0px !important;
  color: #000;
}
.field-title-new {
  font-size: 14px !important;
  font-weight: 400 !important;
  /* padding: 0px 0px 8px 0px !important; */
  color: #000;
}
.page-heading {
  font-size: 34px !important;
  color: #265aa8;
  font-weight: 700 !important;
}
::placeholder {
  color: #8a8a8a !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  top: 10px !important;
  right: 15px !important;
}

.disable-btn {
  background: #c9c9c9 !important;
  color: #ffffff !important;
}

/*To hide Mui datagrid vertical divider */
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.css-biwvik-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
.css-1o0mzy7-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

/* Datagrid styles */
.MuiDataGrid-root {
  border: none !important;
}

.css-1vrg69a-MuiDataGrid-root .MuiDataGrid-row {
  cursor: pointer !important;
}
.css-c3z2vd-MuiDataGrid-root .MuiDataGrid-row {
  cursor: pointer !important;
}

.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
  width: 99.9% !important;
  border-left: 1px solid #dedede !important;
  border-right: 1px solid #dedede !important;
}

.css-204u17-MuiDataGrid-main {
  overflow: inherit !important;
  cursor: pointer !important;
}

.css-tu3chp-MuiDivider-root {
  margin: 0px 1px 0px 5px !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 400 !important;
  color: #000000 !important;
  font-size: 14px !important;
  line-height: 1.3 !important;
  padding: 0% !important;
  text-overflow: clip !important;
  white-space: break-spaces !important;
}
.css-z8fhq1-MuiDataGrid-columnHeaders {
  background-color: #f6f6f6 !important;
  border: 1px solid #dedede;
}
/* .MuiDataGrid-columnHeaderTitle {
  text-overflow: clip !important;
  white-space: break-spaces !important;
  line-height: 1.3;
  padding: 5px 0px 5px 0px;
} */
.css-1g7nc1s-MuiPickersLayout-root{
  display: block !important;
}
.css-1da33yf-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  /* overflow: none !important; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-icons-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ml {
  margin-left: 8px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px !important;
}

.css-zun73v {
  padding: 0px !important;
}
.table-height {
  max-height: 100%;
  margin-bottom: 30px !important;
  overflow: hidden !important;
}
.table-height-history {
  height: 181px !important;
  margin-bottom: 30px !important;
  overflow: hidden !important;
}

.table-height-ticket {
  height: 100% !important;
  margin-bottom: 30px !important;
}

.table-toolbar {
  display: flex !important;

  justify-content: space-between !important;

  align-items: center;
}
.department-table-width {
  width: 60% !important;
}
.department-group-table-width {
  width: 60% !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .department-table-width {
    width: 100% !important;
  }
  .department-group-table-width {
    width: 100% !important;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .department-table-width {
    width: 90% !important;
  }
  .department-group-table-width {
    width: 90% !important;
  }
}

.list-title {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #020202 !important;
}

.MuiDataGrid-row {
  padding: 5px 0px !important;
  border-bottom: 1px solid #dedede;
  max-height: max-content !important;
  min-height: max-content !important;
}
.css-6ok8od-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  max-height: max-content !important;
}
.MuiDataGrid-cellContent {
  text-overflow: unset !important;
  overflow-wrap: anywhere !important;
}

.add-img-btn {
  border-radius: 4px !important;
  padding: 5px 0px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #0160b9 !important;
  align-self: center !important;
}

.remove-icon {
  display: flex;
  align-items: center;
  color: #265aa8;
  justify-content: flex-end;
}

.validation-text {
  color: red !important;
  font-size: 12px !important;
}
/* All details page Span */

.span-color {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.menu-item-span {
  color: #d8d3d3 !important;
  font-size: 14px !important;
}
.optional-span {
  font-size: 12px !important;
  color: #9e9e9e !important;
}
.basic-details-limits {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 350px !important;
}

/* Notification Modal CSS */
.notification-modal-container {
  width: 355px;
  background-color: #ffffff;
  color: black;
  height: 100%;
}
.notification-modal-body {
  padding: 12px;
}
.circle-noti-icon {
  color: #3286e9;
  font-size: 2.3rem !important;
}
.notification-title {
  font-weight: 600 !important;
  font-size: 15px !important;
}
.notification-link {
  border-bottom: "2px solid #265aa8";
  color: #265aa8;
  cursor: pointer;
}
.font-15 {
  font-size: 15px !important;
}
.font-12 {
  font-size: 12px !important;
}

/* dashboard component */
.MuiPaper-root-MuiCard-root {
  padding: 0px !important;
  margin: 0px !important;
}

/* Link text decoration */
.text-decoration {
  text-decoration: none;
}

/* medioa Query */
.step-node-submit-btn {
  width: 85%;

  position: relative;
  height: calc(100vh - 150px);
}
.step-node-right {
  width: 85%;
  position: relative;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1976d2;
  font-weight: 500 !important;
}

/********************************** Live classes *****************************/
.css-1x5jdmq {
  height: 40px !important;
  padding: 1px 14px !important;
  font-size: 14px !important;
}
.css-1q2h7u5.Mui-selected {
  color: #1976d2;
  font-weight: 500 !important;
}
.css-1au5wsq {
  background-color: #f6f6f6 !important;
  border: 1px solid #dedede;
}
.css-1inm7gi {
  border-left: 1px solid #dedede !important;
  border-right: 1px solid #dedede !important;
  width: 99.9% !important;
}
/* Auto complete  */
.MuiAutocomplete-hasPopupIcon.css-xxees4 .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-xxees4 .MuiOutlinedInput-root {
  border-radius: 4px !important;
  padding: 0px 13px 0px 13px !important;
  height: 42px !important;
}
.MuiAutocomplete-hasPopupIcon.css-hrqg3d .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-hrqg3d .MuiOutlinedInput-root {
  border-radius: 4px !important;
  padding: 0px 13px 0px 13px !important;
  height: 42px !important;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .table-icons-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .step-node-submit-btn {
    width: calc(100vw - 25%);
    position: relative;
    height: calc(100vh - 150px);
  }
  .step-node-right {
    width: 75%;
    position: relative;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .step-node-submit-btn {
    width: calc(100vw - 20%);
    position: relative;
    height: calc(100vh - 150px);
  }
  .step-node-right {
    width: 80%;
    position: relative;
  }
}

/* RC TIME PICKER */

.rc-time-picker-panel.rc-time-picker-panel-column-3.rc-time-picker-panel-placement-bottomLeft {
  z-index: 1400 !important;
  width: 222px !important;
}
.rc-time-picker-panel {
  z-index: 1400 !important;
  width: 222px !important;
}
.rc-time-picker-panel-select {
  width: 74px !important;
}
.rc-time-picker-panel-narrow {
  max-width: 149px !important;
}
li.rc-time-picker-panel-select-option-selected {
  background: #f6f6f6 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #0160b9 !important;
}
.rc-time-picker-panel-select li {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0px !important;
}

/* Custom CSS to style the scrollbar */
.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 3px; /* Adjust the width as per your preference */
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background-color: #cdcdcd; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Adjust the border-radius to control the roundness of the thumb */
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb:active {
  background-color: #333; /* Color of the scrollbar thumb when active (clicking) */
}
.loading-background {
  z-index: 1410 !important;
}

/* vd Changes */
.footer-padding {
  padding: 16px 56px 12px 20px !important;
}

/* Day Picker */
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root {
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
  margin: 8px 8px !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #fff !important;
  background-color: #0160b9 !important;
}
.css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
  min-height: 254px !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
  margin: 8px 8px !important;
}
.css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel {
  margin: 0px 2px !important;
}
.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root {
  border-radius: 8px !important;
}
/* ***** */
/* Date live classes */
.css-wed0tz.Mui-selected {
  color: #fff !important;
  background-color: #0160b9 !important;
}
.css-wed0tz {
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
  margin: 6px 8px !important;
}
.css-ub1r1 {
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
  margin: 6px 8px !important;
}
.css-10qkmc3 {
  margin: 0px 2px !important;
}
.css-f4m3aa {
  border-radius: 8px !important;
}

/* Date Picker */
.css-1uvydh2 {
  padding: 9.5px 0px 9.5px 14px !important;
}
.css-qiwgdb {
  padding: 9.5px 14px !important;
}

/* Common Padding */
.main-padding {
  padding: 15px 56px 15px 20px !important;
}
@media (min-width: 0px) and (max-width: 1440px) {
  .main-padding {
    padding: 15px 30px 15px 56px !important;
  }
}

.main-component-padding {
  padding: 0px 56px 18px 56px !important;
}

/* pagination css */

.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root {
  min-width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
}

.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #ffffff !important;
  background: #0160b9 !important;
}
/* Live for pagination */
.css-1b5ei7m {
  min-width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
}
.css-1b5ei7m.Mui-selected {
  color: #ffffff !important;
  border: 1px solid rgba(25, 118, 210, 0.5) !important;
  background-color: #0160b9 !important;
}

.pagination-wrapper {
  display: flex !important;
  justify-content: flex-end !important;
  position: absolute !important;
  width: 93.5% !important;
  bottom: 40px !important;
}
.error-task {
  color: red;
  font-size: 20px;
}
.date-child-box {
  font-size: 14px;
  font-weight: 600;
  color: #1c1c1c;
  text-align: end;
}
.time-child-box {
  font-size: 24px;
  font-weight: 600;
  color: #265aa8;
  text-align: end;
}

/* media Query */
@media (min-width: 0px) and (max-width: 1024px) {
  .main-padding {
    padding: 12px 16px 12px 16px !important;
    height: 83px !important;
  }
  .main-title {
    font-size: 18px !important;
    width: 200px !important;
  }
  .autocomplete {
    width: 160px !important;
  }
  .search-input {
    width: 160px !important;
  }
  .avatar-box {
    /* width: 200px !important; */
    gap: 15px !important;
  }
  .css-2s90m6-MuiAvatar-root {
    width: 24px !important;
    height: 24px !important;
    font-size: 14px !important;
  }

  .avatar-box-width {
    font-size: 16px !important;
    width: 20px !important;
  }
  .role-name {
    font-size: 12px !important;
  }
  .display-name {
    font-size: 14px !important;
  }
  .date-child-box {
    font-size: 14px !important;
  }
  .time-child-box {
    font-size: 17px !important;
  }
  .footer-padding {
    padding: 16px 16px 12px 20px !important;
  }

  .main-component-padding {
    padding: 0px 16px 18px 16px !important;
  }
  .css-8xy5pc-MuiDataGrid-root .MuiDataGrid-row {
    width: 99.5% !important;
  }
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
    width: 99.7% !important;
  }
  .css-1inm7gi {
    border-left: 1px solid #dedede !important;
    border-right: 1px solid #dedede !important;
    width: 99.7% !important;
  }
  .pagination-wrapper {
    display: flex !important;
    justify-content: flex-end !important;
    position: absolute !important;
    width: 96.5% !important;
    bottom: 40px !important;
    height: 0px !important;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1040px) {
  .css-8xy5pc-MuiDataGrid-root .MuiDataGrid-row {
    width: 99.66% !important;
  }
  .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
    width: 99.8% !important;
  }
  .css-1inm7gi {
    border-left: 1px solid #dedede !important;
    border-right: 1px solid #dedede !important;
    width: 99.8% !important;
  }
  .pagination-wrapper {
    display: flex !important;
    justify-content: flex-end !important;
    position: absolute !important;
    width: 91.1% !important;
    bottom: 40px !important;
    height: 0px !important;
  }

}

.access-height {
  height: 500px !important;
}
.access-top-up {
  position: absolute !important;

  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: flex !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.uploadAttachmentIcon {
  display: flex !important;
}
.uploadAttachmentIconChild {
  justify-content: space-between !important;

  text-transform: capitalize;
  color: #2f2f2f;
}
.uploadAttachmentIconChild2 {
  padding-left: 290px;
}

.quantityTable {
  border-collapse: collapse;
  border-color: #dedede;
  margin-left: 20px !important ;
  width: 57% !important;
}
.dateUsedRow {
  padding: 10px 16px !important;
  width: 37% !important;
}
.qtyRow {
  padding: 10px 16px !important;
  width: 80% !important;
}
.product-header {
  font-size: 12px;
  padding-left: 5px;
  font-weight: 700;
}
.navbar-main-div {
  position: fixed;
  background: white;
  z-index: 100;
  width: calc(100% - 270px);
  height: 82px;
}

.common-parent-class {
  padding: 35px;
}
.resource-common-parent-class {
  padding: 0px 50px;
}

.tab-head {
  text-transform: capitalize !important;
  font-size: 14px !important;
}
.css-1ujnqem-MuiTabs-root {
  border-bottom: 1px solid #dedede !important;
}
.common-tabel-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.common-parent-haeding-btn-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date-box {
  margin-left: 25px;
}


.css-qvtrhg-MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
}
.css-r0cs7x-MuiDataGrid-root .MuiDataGrid-cell:focus-visible {
  outline: none !important;
}
.css-r0cs7x-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiTableCell-root {
  padding: 10px !important;
}
.css-1q1u3t4-MuiTableRow-root {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}
.css-1q1u3t4-MuiTableRow-root:hover {
  background: #f6f6f6;
}
.css-15wwp11-MuiTableHead-root {
  background: #f6f6f6;
}

.details-label {
  font-size: 14px !important;
  color: #8a8a8a;
  font-weight: 400;
  padding-bottom: 20px;
}
.details-label-text {
  font-size: 14px !important;
  color: #8a8a8a;
  font-weight: 400;
  padding-bottom: 10px;
}

.details-ans {
  font-size: 14px !important;
  color: #020202;
  font-weight: 400;
}
.basic-details-status {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5px;
  font-size: 14px;
}
.basic-form-space {
  display: flex;
  justify-content: space-between;
  padding: 0px 40px !important;
}

.basic-font-size-14-with-semibold {
  font-size: 14px;

  font-weight: 600;
  color: #1c1c1c;
}
.job-parent-class {
  padding: 10px 25px 25px 25px;
}
.breadcrumb-share-save {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
.share-file-header-share {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 600;
  display: flex;
  gap: 10px;
  color: #265aa8;
  cursor: pointer;
}
.disabled-balance-Quantity {
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 6px 0px;
  color: #a6a6a6;
}
.job-card-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
}
.common-parent-class-overflow-auto-padding-10-50 {
  padding: 10px 50px;
  height: calc(100vh - 85px);
  overflow: auto;
}
.common-parent-class-overflow-machine {
  padding: 0px 50px;
  height: calc(100vh - 140px);
  overflow: auto;
}
.common-parent-class-overflow-auto-285 {
  padding: 10px 50px;
  height: calc(100vh - 285px);
  overflow: auto;
}
.common-parent-class-overflow-auto {
  padding: 35px;
  height: calc(100vh - 85px);
  overflow: auto;
}
.report-form-wrapper-overflow-auto {
  height: calc(100vh - 85px);
  overflow: auto;
  padding-top: 20px;
}
.production-team-head-overflow-hidden {
  height: 100vh;
  overflow: hidden;
}
.production-team-head-overflow-auto {
  height: calc(100vh - 85px);
  overflow: auto;
}
.role-team-head-overflow-auto {
  height: calc(100vh - 230px);
  overflow: auto;
  padding: 0px 50px 10px;
}

.css-1p1z0ee-MuiPaper-root-MuiCard-root {
  border-radius: 0px !important;
}
.css-964n58-MuiPaper-root-MuiCard-root {
  border-radius: 0px !important;
}
.css-rmk2mx-MuiPaper-root-MuiCard-root {
  border-radius: 0px !important;
}

.table-icon-new {
  display: flex;
  gap: 10px;
}
.table-icon-new-sale {
  display: flex;
  gap: 10px;
}
@media (min-width: 0px) and (max-width: 1024px) {
.set-search{
  width: 30%;
}
}
@media (min-width: 1250px) and (max-width: 1440px) {
/* @media (min-width: 1025px) and (max-width: 1420px) { */
  .table-icon-new {
    gap: 5px;
    /* display: block; */
  }
  .table-btn-main {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 3px;
  }
  .table-icon-new-sale {
    display: flex;
    /* gap: 65px; */
    gap: 15px;
  }
}
@media (min-width: 0px) and (max-width: 1430px) {
  .table-icon-new {
    gap: 5px;
    /* display: block; */
  }
  .table-btn-main {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 3px;
  }

  .table-icon-new-sale {
    display: flex;
    gap: 5px;
  }
}

.nested-actions {
  display: flex;
  gap: 24px;
  color: #0160b9;
  font-size: 14px;
  font-weight: 600;
}
.parent-nested{
  margin: 2rem 6rem 2rem 2rem;
}
.nested-actions-push-cursor-pointer {
  display: flex;
  cursor: pointer;
  color: #0160b9 !important;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  margin-left: 20px;
  margin-top: 5px;
}
.nested-actions-push {
  display: flex;
  color: #0160b9 !important;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  margin-left: 20px;
  margin-top: 5px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .nested-actions {
    flex-direction: column;
    font-size: 12px;
    gap: 10px;
  }
}

.tab-panel-padding {
  padding: 0px 50px !important;
}
.main-sub-padding {
  padding: 24px 50px 0px 50px !important;
}
.tab-content-padding {
  padding: 10px 50px !important;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .set-search{
    width: 30%;
  }
  }
.divider-content-padding {
  padding: 0px 50px !important;
}
.padding-x-50 {
  padding: 20px 50px 10px;
}
.padding-24-50 {
  padding: 24px 50px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .tab-panel-padding {
    padding: 0px 17px !important;
  }
  .main-sub-padding {
    padding: 24px 17px 0px 17px !important;
  }
  .common-parent-class-overflow-auto-padding-10-50 {
    padding: 10px 16px;
  }
  .common-parent-class-overflow-machine {
    padding: 10px 16px;
    
  }
  .common-parent-class-overflow-auto-285 {
    padding: 10px 32px;
  }
  .tab-content-padding {
    padding: 10px 16px !important;
  }
  .padding-x-50 {
    padding: 20px 16px 10px;
  }
  .padding-y-50 {
    padding: 0px 16px !important;
  }
  .padding-24-50 {
    padding: 24px 16px;
  }
  .divider-content-padding {
    padding: 0px 16px !important;
  }
  .role-team-head-overflow-auto {
    padding: 0px 16px 10px;
  }
}
.css-j1eobo-MuiPaper-root-MuiCard-root {
  border-radius: 0px !important;
}

.disable-text {
  background: none !important;
  color: #c9c9c9 !important;
}
.css-1pzb349{
  position: static !important
}
div#mui-component-select-machines  {
  padding-right: 228px !important;
}

.MuiDataGrid-columnHeaderTitle{
  text-overflow: clip !important;
  white-space: break-spaces !important;
  line-height: 1;
  /* padding: 5px 0px 5px 0px; */
}
.css-160unip{
 overflow: inherit !important;
}

::placeholder {
  color: #8A8A8A !important;
  font-size: 14px !important;
}
.disabled-only-div{
  color: #a9a9a9 !important;
  text-transform: none !important;
}
.progress-parent12{
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
}

  @media (min-width: 1025px) and (max-width: 1440px) {
    .progress-parent12{
      width: 375px;
    }
    }
    @media (min-width: 0px) and (max-width: 1024px) {
      .progress-parent12{
        width: 225px;
      }
      }

.progress-parent-dashboard-table{
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DEDEDE;
}
.progress-parent-dashboard-jobcard-status{
  height: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DEDEDE;
}

.progress-parent-card-info{
  height: 120px;
  /* width: 850px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DEDEDE;
}
.css-192l38i{
  display: block !important;
}
.machine-kanban-progress{
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* nested table css */
.css-3joe2j-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: none !important;
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 1000px;
  margin-left: 50px;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    /* -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    /* -webkit-transition: none !important; */
    /* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    /* transition: none !important; */
    /* border-radius: 4px; */
    border: 1px solid #DEDEDE;
    box-shadow: none !important;
    /* box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.2); */
    width: 100%;
    overflow-x: auto;
}
.job-slips-with-print{
  /* display: none; */
  visibility: hidden;
  /* z-index: -100; */
}
/* // For invoice printing */
@media print {
   /* .compresswidth {
    max-width: 750px !important;
    min-width: 750px !important;
   } */

   .main-padding {
    display: none !important;
   }
    .job-slips-with-print {
      /* max-width: 750px !important;
      min-width: 750px !important; */
      /* border: 2px solid red; */
      /* display: block !important; */
      /* display: visible !important; */
      visibility: visible;
    }
   .job-slips-add-task-header{
    font-size: 14px !important;
  color: #8a8a8a !important;
  font-weight: 400 !important;
   }
   .job-slips-grid-section{
    display: flex !important;
    gap: 50px !important;
    width: 547px !important;
    /* height: 60px !important; */
    padding: 20px !important;
   }
   .job-slips-main-border {
    border: 1px solid #cdcdcd !important;
    border-radius: 3px !important;
  }
  .job-slips-main-margin {
    margin: 20px 0px !important;
  }
  .job-slips-task-assigned {
    display: flex !important;
    gap: 5px !important;
  }
  .job-slips-with-semibold {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    color: #1c1c1c !important;
    padding-right: 10px !important;
  }
 
  .common-table{
    border: 1px solid red
  }
  .job-slips-overflow-auto {
    /* height: calc(100vh - 155px);
    overflow: auto; */
    padding: 20px 100px !important;
  }

  /* .css-bs75ob-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
} */
  }
  .print-table{
    width: 100% !important;
  }
  .table-border-print{
    border: 1px solid #DEDEDE;
    border-spacing: 0;
    font-size: 12px!important;
    border-radius: 4px 4px 0px 0px!important;
   
  }
  .table-border-print-th{
    /* border-bottom: 1px solid #DEDEDE; */
    background: #F6F6F6!important;
    color: #020202;
    font-size: 12px!important;
    font-weight: 400;

  }
  .table-border-print-td{
    color: #020202;
    font-size: 12px!important;
    font-weight: 400;
    border-top: 1px solid #DEDEDE;
  }
  .red-text{
    color: red !important;
  }
 .modified-Fields-color{
  color: #0160B9 !important;
  font-size: 12px !important;
 }