.dash-border-main-box {
  height: 100vh;
  overflow: hidden;
}
.dash-border-box-over {
  height: calc(100vh - 85px);
  overflow: auto;
}
.job-title {
  font-size: 18px;
  font-weight: 600 !important;
  color: #020202;
  padding: 15px;
}

.parent-job-details {
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #dedede;
}

.ongoing-title {
  font-size: 16px;
  font-weight: 600 !important;
  width: 280px;
}

/* //! parent-table-wrapper css */

.parent-table-wrapper {
  border: 1px solid #dedede;
  padding: 0px 10px;
  height: 279px !important;
}

.wrapper-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 15px 0px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .ongoing-title {
    font-size: 14px !important;
    /* font-weight: 600 !important;
    width: 240px; */
  }
}
