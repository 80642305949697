.form-wrapper {
  padding-top: 20px;
}

.form-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  padding: 20px 60px !important;
}
.common-tabel-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
}
.form-heading-1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padding-y-20 {
  padding: 0px 20px !important;
}

.padding-y-40 {
  padding: 0px 40px !important;
}

.padding-y-60 {
  padding: 0px 60px !important;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .padding-y-60 {
    padding: 0px 16px !important;
  }
}
.padding-20-60-40 {
  padding: 20px 60px 40px !important;
}

.padding-20 {
  padding: 20px !important;
}

.form-perent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.form-child {
  height: calc(100vh - 308px);
  overflow: auto;
}

.button-child {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* box-shadow: ; */
  /* paddin: 3%; */
}

.filedArray-perent {
  border: 1px solid #dedede;
  padding: 20px;
  margin: 0px 40px !important;
  border-radius: 4px;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.padding-x-20 {
  padding: 20px 0px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gap-20 {
  display: flex;
  flex-direction: column;
  gap: 20px !important;
}

.add-details-span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #0160b9;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.table-btn-main {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  /* padding-right: 10px; */
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center-with-gap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
}

.font-size-14 {
  font-size: 14px;
  line-height: 20px;
  color: #8a8a8a;
}

.font-size-14-with-semibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1c1c1c;
}

.font-size-16-with-semibold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #1c1c1c;
  padding: 8px 24px 8px 24px;
}

.font-size-14-with-semibold-primary {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #0160b9;
}

.parent-com {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #cdcdcd;
  width: 40%;
  margin-bottom: 5px;
}

.border-border-radius {
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}

.padding-2-border-bg {
  padding: "2px";
  border-bottom: 1px solid #cdcdcd;
  background-color: #f6f6f6;
}

.padding-5 {
  padding: 5px 40px;
}

.padding-x-60 {
  padding: 20px 60px 10px;
}

.padding-10 {
  padding: 10px 40px;
}

.padding-x-40 {
  padding: 20px 40px;
}

.padding-10-2 {
  padding: 10px 2px;
}
.padding-20-75 {
  padding: 20px 75px;
}
.process-Edit {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
}
.process-add {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
  color: #0160b9;
}
.process-retsore {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* padding-right: 20px; */
  color: #0160b9;
  /* line-height: 20px; */
}
.process-Restore-name {
  /* padding-left: 7px; */
  color: #0160b9;
  font-size: 14px;
  font-weight: 600;
  padding-top: 9px;
  line-height: 20px;
  cursor: pointer;
}
.process-Edit-name {
  padding-left: 7px;
  color: #0160b9;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.factory-table-btn-main {
  display: flex;
  align-items: center;
  gap: 20px;
}
.css-1phpx1i-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--light-gray-color) !important;
}

.default-date-picker {
  width: 100% !important;
  border-radius: 4px;
  border-color: #8a8a8a;
  /* font-size: 14px !important; */
  /* padding: 0px 14px; */
  line-height: 0px;
  height: 40px;
}

.default-date-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 40px !important;
  padding: 0px 14px !important;
  font-size: 14px !important;
}

.date-title {
  font-size: 14px !important;
  font-weight: 400;
  /* padding: 0px 0px 6px 0px; */
  color: #000;
}
.validation-date {
  color: red;
  font-size: 12px;
}

.delete-item-overflow-hidden {
  height: 100vh;
  overflow: hidden;
}
.delete-item-overflow-auto {
  height: calc(100vh - 85px);
  overflow: auto;
  padding: 35px;
}
