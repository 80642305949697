:root {
  --font-xs: 12px;
  --font-sm: 14px;
  --font-lg: 18px;
  --backgroud: #f0f6ff;
}

.parent-Card-info {
  border: 1px solid #dedede;
  padding: 10px 15px;
  background: var(--backgroud);
  width: 100%;
}

.info-title {
  font-size: var(--font-sm) !important;
  font-weight: 400;
  padding-bottom: 5px;
}

.card-value {
  font-size: var(--font-lg) !important;
  font-weight: 700 !important;
}

.info-description {
  font-size: var(--font-xs) !important;
  color: #8a8a8a;
}

.grid-info-1 {
  border-right: 1px solid #c0c0c0;
}

.grid-info-2 {
  padding-left: 20px;
}

.info-title-card {
  font-size: var(--font-sm) !important;
  font-weight: 400;
  padding-bottom: 5px;
  max-width: 130px;
  /* border: 1px dotted red; */
  min-height: 41px;
}

.parent-details-info {
  /* align-items: center !important; */
}

.parent-one {
  padding: 10px 15px;
  border: 1px solid #dedede;
  background: var(--backgroud);
}

.parent-job-data {
  border: 1px solid #dedede;
  padding: 14px 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}

.parent-product-data {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;
  gap: 10px;
}

.parent-details-one {
  padding-top: 16px;
}

.parent-spacing {
  margin-top: 16px !important;
}

.single-job-parent {
  padding-right: 14px !important;
  margin-top: 15px !important;
}

@media (min-width: 0px) and (max-width: 1200px) {
  .parent-job-data {
    padding: 8px;
    gap: 5px;
  }
  .info-title {
    font-size: 10px !important;
  }
  .card-value {
    font-size: 12px !important;
  }
  .info-description {
    font-size: 10px !important;
  }
  .parent-Card-info {
    padding: 8px;
  }
  .parent-one {
    padding: 8px !important;
  }
  .info-title-card {
    font-size: 10px !important;
  }
  .parent-spacing {
    margin-top: 0px !important;
  }
  .grid-info-2 {
    padding-left: 14px;
  }
}
@media (min-width: 1201px) and (max-width: 1550px) {
  .grid-info-2 {
    padding-left: 16px;
  }
}