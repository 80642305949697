.css-1phpx1i-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--light-gray-color) !important;
}

.default-date-time-picker {
  width: 100% !important;
  border-radius: 4px;
  border-color: #8a8a8a;
  font-size: 14px;
  padding: 10px 12px;
  line-height: 0px;
  /* height: 40px; */
}
.default-date-time-picker
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 14px !important;
  height: 40px !important;
}

.date-time-title {
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 6px 0px;
  color: #000;
}
.validation-date-time {
  color: red;
  font-size: 12px;
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 344px !important;
  height: 40px !important;
} */
