.add-reports-border {
   border: 1px solid rgba(192, 192, 192, 1);
   padding: 15px 50px 30px 40px;
}
.date-picker-fieldset {
    width: 100%;
    height: 10%;
    padding: 1.5% 1%,
}
.date-picker {
    width: 100%;
    height: 10%;
    padding: 1.5%;
}
.mr-15 {
gap: 10;
margin-left: 10px;
}
/* .mr-16 {
    gap: 50;
    margin-left: 20px;
} */