.form-wrapper {
  padding-top: 20px;
}
.form-heading {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  padding: 20px 60px !important;
}
.create-task-form-heading-1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-task-form-heading-main {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
  line-height: 28px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px !important;
}
.create-task-form-perent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.create-task-form-child {
  height: calc(100vh - 185px);
  overflow: auto;
}
.create-task-button-child {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* box-shadow: ; */
  /* paddin: 3%; */
}
.create-task-form-padding {
  padding: 0px 50px 10px;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .create-task-form-heading-main {
    padding: 20px 16px !important;
  }
  .create-task-form-padding {
    padding: 0px 16px 10px;
  }
}
