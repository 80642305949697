.approved-job-card-main-box {
  /* width: 80%; */
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center !important;
  /* display: flex;
  justify-content: center; */
}

.approved-job-card-main-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 50px;
}
.approved-job-card-box {
  width: 100%;
  /* display: flex; */
  /* justify-content: start; */
  align-items: center;
  /* text-align: center; */
}
.approved-job-card-date {
  margin-top: 30px;
}
.approved-padding {
  padding-top: 20px;
}
.approved-yes-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.approved-yes-btn1 {
  display: flex;
  justify-content: center !important;
  margin-top: 35px !important;
}
